<template>
  <div class="entry">
    <div class="gestion-hero">
      <div class="text-container">
        <h1 class="title">
          Logiciel de gestion locative en ligne
          <span>N°1 du marché</span>
        </h1>
        <div class="subtitle">
          Pour les propriétaires bailleurs qui gèrent seuls leurs biens immobiliers.
        </div>
        <uikit-button
          type="primary"
          class="button"
          @click="redirectToGestion"
        >
          Découvrir la gestion locative
        </uikit-button>
      </div>
      <div class="img-container">
        <img
          class="computer"
          src="~/assets/imgs/computer-background.webp"
          alt="préavis"
          loading="lazy"
          width="650"
          height="384"
        >
        <div class="cards">
          <div class="card">
            +250 000 utilisateurs et +5 ans d'existence ✨🚀
          </div>
          <div id="map" />
          <div class="card">
            <span>{{ getRating }}/5 sur Google</span>
            ⭐
          </div>
        </div>
      </div>
    </div>
    <div class="partners">
      <SectionsPartners />
    </div>
  </div>
</template>

<script setup>
import { UikitButton } from '@hz/ui-kit'
import { useAuthStore } from '../../../stores/auth'

const authStore = useAuthStore()
const isPin = computed(() => authStore.isPIN)
const { data: rating } = await useLazyAsyncData(() => useGoogleApi().getRating())

const getRating = computed(() => {
  const value = rating.value ?? 4.5

  return value.toLocaleString()
})

function redirectToGestion() {
  window.location.href = isPin.value
    ? useUrls().realEstatesUrl()
    : `${useUrls().onboardingPINUrl(true)}?tracking=enable&positionBouton=haut`
}
</script>

<style lang="less" scoped>
.entry {
  .app-padding();
  background-color: var(--ds-color-primary-25);
  padding-top: 50px;
  padding-bottom: 50px;
  .gestion-hero {
    display: grid;
    margin-bottom: 50px;
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;
    .partners {
      grid-column: span 2;
      .partners-list {
        width: 100%;
      }
    }
  }
  .title {
    font-size: 28px;
    margin-top: 0px;
    span {
      color: var(--ds-color-primary-100);
    }
  }
  .text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
  }
  .subtitle {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: normal;
    span {
      font-weight: bold;
    }
  }

  .img-container {
    background: url('~/assets/imgs/dot-background.webp');
    background-size: contain;
    transition-delay: 100ms;
    position: relative;
    width: 345px;
    height: 216px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .computer {
      max-width: 320px;
      height: 100%;
      align-self: center;
      margin: 0 auto;
    }
    .cards {
      display: flex;
      flex-direction: row;
      gap: 10px;
      position: absolute;
      bottom: -13px;
      justify-content: center;
      width: 100%;
      font-weight: var(--ds-weight-semi-bold);

      .card {
        background: var(--ds-color-white-100);
        box-shadow:
          0px 0px 2px rgba(0, 0, 0, 0.06),
          0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 30px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        white-space: nowrap;
        span {
          margin-right: 8px;
        }

        &:last-child {
          color: #fcb017;
        }
      }
    }
  }
}

@media @bp-xlg {
  .entry {
    .gestion-hero {
      gap: 30px;
      max-width: 1700px;
      margin: 0 auto 70px auto;
      grid-template-columns: 1fr 1fr;
    }
    .title {
      font-size: 34px;
      margin-top: 20px;
      margin-right: 70px;
    }
    .subtitle {
      margin-bottom: 30px;
    }
    .text-container {
      text-align: left;
      .title {
        margin-bottom: 40px;
      }
    }
    .img-container {
      width: 650px;
      height: 384px;
      .computer {
        max-width: 570px;
        height: auto;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
      .cards {
        .card {
          height: 42px;
          font-size: 14px;
          padding: 7px 17px;
        }
      }
    }
  }
}

@media @bp-md-only, @bp-lg-only {
  .entry {
    .gestion-hero {
      justify-content: space-evenly;
    }
  }
}
</style>
