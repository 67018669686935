<template>
  <div id="gestion">
    <gestion-SectionsHero id="hero" />
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsSeparator
        class="tools"
        title="Des outils dédiés à la gestion locative en ligne"
        heading-type="h2"
        bloc-number="Bloc 2"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsTools id="tools" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsSeparator
        title="Simplifiez-vous les tâches les plus fastidieuses de la gestion locative."
        subtitle="Rejoignez les milliers de propriétaires qui utilisent nos outils."
        :link="gestionLink"
        :btn-type="'secondary'"
        bloc-number="Bloc 1"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsWhy id="why" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsDownload id="download" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsSeparator
        title="Gagnez du temps et de l’argent."
        subtitle="La gestion de votre patrimoine est notre priorité."
        :link="gestionLink"
        :btn-type="'secondary'"
        bloc-number="Bloc 3"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsOffers id="offers" />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-f-a-q
        id="faq"
        :faq="faq"
      >
        <template #title>
          <h2 class="title">
            Nous avons réponse à <strong>toutes vos questions</strong>
          </h2>
        </template>
      </lazy-sections-f-a-q>
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazyGestion-SectionsSeparator
        title="Envie de tester la gestion locative en ligne ?"
        :link="offerLink"
        :btn-type="'secondary'"
        bloc-number="Bloc 4"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-blog-articles
        id="news-section"
        top-cta-link="https://horiz.io/gestion-immobiliere"
        :content="blogContent"
        title="Conseils et actus sur la <strong>gestion immobilière</strong>"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <LazySectionsCustomerReviews
        title="Des milliers de propriétaires sont devenus experts de la gestion locative."
      />
    </nuxt-lazy-hydrate>
  </div>
</template>

<script setup>
import { BENEFIT_GLI, PNO_UNDER_110_SQUARE_METERS, GLI_PARTNER_URL, PNO_PARTNER_URL, LMNP_PARTNER_URL  } from '@/config/config'

const config = useRuntimeConfig()
const { data: articles } = await useLazyAsyncData(() => useCmsApi().getLandingGestionArticles(), {
  default: () => ([])
})

const blogContent = computed(() => ({
  articles: articles.value,
}))

const faq = [
  {
    title: 'Votre service est-il déductible des impôts ?',
    content:
      "Oui dans le cas d'un bien loué meublé, imposé au réel ou encore d'une <a href='https://horiz.io/investissement-immobilier/sci-le-guide-complet'>SCI</a> à l'IS. Par exemple, si vous êtes célibataire, imposé dans la tranche des 30%, vous ferez une économie d'impôts de 30% du prix de votre abonnement.<br /> Si vous êtes en location non meublée, c'est inclus dans les 20€ forfaitaires déduits dans votre déclaration d'impôts.",
  },
  {
    title: 'Qui gère mon bien ?',
    content:
      "C'est vous ! <br />Notre outil vous accompagne dans toutes les étapes de la gestion locative pour vous aider à ce que cela soit moins fastidieux et chronophage.<br />Notre plateforme a été conçue pour être éducative et comprise par tous, pour que vous soyez autonome.",
  },
  {
    title: 'Est-ce que je continue à toucher les loyers en direct ?',
    content:
      "Vous continuez à être le seul et unique bénéficiaire de vos loyers. Pas de mandat de gestion chez nous.<br />Nous n'encaissons pas vos loyers et nous ne prenons pas de pourcentage dessus.",
  },
  {
    title: 'Vos documents sont-ils à jour des dernières lois ?',
    content:
      'Quittance, bail, inventaire, etc. tous nos documents sont à jour.<br />Et nous vous accompagnons dans la rédaction de votre bail pour que celui-ci soit conforme aux réglementations.',
  },
  {
    title: 'Quels types de baux faites-vous ?',
    content:
      'Nous proposons des baux pour les particuliers : meublé, nu et mobilité. Ils peuvent être individuels / en colocation avec ou sans clause de solidarité.',
  },
  {
    title: "Proposez-vous des assurances qui couvrent en cas d'incident ?",
    content: `Vous pouvez bénéficier de nos assurances PNO et GLI à des prix imbattables.<br /><a href="${PNO_PARTNER_URL}">PNO</a> (propriétaire non occupant): ${PNO_UNDER_110_SQUARE_METERS}€*<br /><a href="${GLI_PARTNER_URL}">GLI</a> (garantie loyers impayés) : à partir de ${BENEFIT_GLI}% du montant de votre loyer<br />*dans 98% des cas, mais il se peut que votre bien soit d'exception et qu'à ce titre le prix soit réévalué à la hausse.`,
  },
  {
    title: 'Gérez-vous la comptabilité de mes locations (liasses fiscales LMNP, SCI, etc.) ?',
    content:
      `Oui nous vous avons sélectionné un partenaire cabinet <a href='${LMNP_PARTNER_URL}'>expert comptable</a> à un prix imbattable : 280€TTC/an.`,
  },
  {
    title: "M'aidez-vous à remplir ma déclaration d'impôts concernant les revenus de mon bien?",
    content:
      "Le moment de la déclaration d'impôts est toujours un calvaire, on comprend votre question !<br />Dans le cas où vous possédez une location non meublée en tant que particulier, nous pré-remplissons automatiquement les champs de votre déclaration d'impôt.<br />Pour une location meublée ou en SCI, vous pourrez entrer les données fournies par le comptable.",
  },
  {
    title: 'Votre outil permet-il de calculer les régularisations de charges, les révisions de loyers, etc ?',
    content:
      "Tout à fait.<br />Après avoir renseigné les informations nécessaires, notre outil calculera automatiquement ces données.<br />Dans le cas où un changement de locataire a eu lieu durant l'année, notre outil sera même dans la capacité de calculer le prorata par locataire.",
  },
  {
    title: "M'aidez-vous à trouver un (nouveau) locataire ?",
    content:
      "C'est à vous de <a href='https://horiz.io/gestion-immobiliere/nos-conseils-pour-votre-recherche-de-locataire-en-2022'>trouver votre locataire</a> mais on vous simplifie cette démarche.<br />Comment ?<br />1/ En vous limitant le nombre d’heures passées au téléphone pour sélectionner les candidats éligibles à une visite. <br />Les candidats intéressés par votre annonce reçoivent un formulaire qui permet à notre outil d'évaluer leur profil et vous indiquer les plus adaptés pour votre location.<br />2/ En planifiant facilement les visites : Proposez un créneau aux candidats en 1 clic. Suivez votre calendrier de visites. Reprogrammez ou annulez si besoin. <br />3/ En récupérant simplement les documents nécessaires à la validation du dossier du futur locataire grâce à un espace en ligne où celui-ci peut déposer ses documents.",
  },
  {
    title: 'Que se passe-t-il si mon locataire ne me paie pas ?',
    content:
      `Vous êtes prévenu de tout retard de paiement grâce à notre système d'alerte.<br />Si malgré les relances, cela perdure, vous pouvez être mis en relation avec nos experts juridiques.<br />Aussi, pour vous couvrir en cas d'impayés, nous vous proposons une Garantie Loyers Impayés à prix imbattable, soit à partir de ${BENEFIT_GLI}% du montant de votre loyer.`,
  },
  {
    title: 'Comment se passe la signature de bail ?',
    content:
      "Signer le bail à distance pour gagner du temps avec la signature électronique.<br />Locataires et garants signent le <a href='https://support.horiz.io/hc/fr/articles/360007520412-Bail-num%C3%A9rique-signature-%C3%A9lectronique-du-bail-de-location'>bail numérique</a> depuis leur ordinateur. Plus besoin de se déplacer. Dès que toutes les parties prenantes ont terminé de signer, vous êtes informés.",
  },
  {
    title: 'Proposez-vous des modèles types de documents ?',
    content:
      "Nous vous proposons en effet des <a href='https://support.horiz.io/hc/fr/articles/212726385-Comment-obtenir-des-mod%C3%A8les-de-documents-bail-etc-'>modèles de documents</a> types comme les inventaires de meuble, les états des lieux, les mises en demeure, etc.<br />MAIS PAS QUE !<br />Par exemple, en fonction de votre situation nous vous pré-remplissons un bail adapté à votre profil.",
  },
  {
    title: 'Est-que je dois rentrer chaque mois le loyer de mon locataire ?',
    content:
      "Rassurez-vous ! Nous pouvons tout automatiser !<br />En reconnaissant automatiquement le paiement des loyers sur votre compte bancaire, tout sera mis à jour et la quittance envoyée. Et s'il y a le moindre impayé, nous vous prévenons d'abord avant de faire quoi que ce soit. ",
  },
  {
    title: 'Est ce possible de gérer plusieurs biens simultanément ?',
    content:
      "Oui, bien sûr ! <br />Que vous ayez 1 bien ou 100 c'est pareil. <br />Vous pouvez tous les gérer sur Horiz.io sans aucune difficulté ni frais supplémentaires.",
  },
]

onMounted(() => {
  useTracking().trackAfterMounted('[GESTION_LP] Accès Landing')
})

// Metas
const title = 'Gestion Locative Immobilière en ligne | Horiz.io'
const url = `${config.public.hpBaseUrl}/gestion-locative`


useMeta({
  title,
  description:
    'Outils de gestion locative : gérez vos biens immobiliers comme un pro ! Pour les propriétaires bailleurs qui gèrent seuls leurs biens immobiliers: bail, quittance, régularisation de charges etc 100% en ligne.',
  image: 'Horizio-gestion-locative.jpg',
  imageAlt: 'Horiz.io, pilotez votre gestion locative en ligne',
  url,
  appleItunesApp: 'app-id=1163613146'
})

useHead({
  title,
  link: [
    {
      rel: 'canonical',
      href: url,
    },
  ],
})

useJsonld([
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Horiz',
    alternateName: 'Rendement Locatif',
    url,
    logo: `${config.public.hpBaseUrl}/imgs/logo.png`,
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: 'Horiz',
    speakable: {
      '@type': 'SpeakableSpecification',
      xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url,
  },
  {
    '@context': 'http://www.schema.org',
    '@type': 'Product',
    name: 'Horiz',
    brand: ' Horiz',
    image: `${config.public.hpBaseUrl}/imgs/logo.png`,
    description: "/html/head/meta[@name='description']/@content",
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: '5',
      reviewCount: '62',
      bestRating: '5 ',
    },
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: config.public.hpBaseUrl,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Gestion locative',
        item: url,
      },
    ],
  },
])
</script>

<script>
export default {
  computed: {
    gestionLink() {
      return {
        link: useUrls().onboardingPINUrl(true, `${this.$config.appBaseUrl}/onboarding/investor`),
        text: 'Découvrir la gestion locative',
        redirect: true,
      }
    },
    offerLink() {
      return {
        link: '/offres',
        text: 'Découvrir notre abonnement',
        redirect: false,
      }
    },
  },
}
</script>

<style lang="less" scoped>
#gestion {
  color: #272727;

  #faq {
    .title {
      color: var(--ds-color-primary-100);
      margin-bottom: 40px;
      margin-top: 60px;
      text-align: center;
    }
  }

  .tools {
    min-height: 200px;
  }
}

@media @bp-desktop {
  :deep(#partners) {
    height: auto;
    display: flex;
    justify-content: center;
  }

  :deep(.partners) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
    }

    .clients {
      padding-bottom: 25px;
      height: 97px;
    }
  }

  #expertise {
    :deep(.expertises-card) {
      margin-bottom: 0;
    }
  }
}

@media @bp-desktop-xl {
  :deep(.partners) {
    margin-top: 90px;
    overflow: auto;
  }
}
</style>
